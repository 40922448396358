import {post} from "../modules/ajax";

export function getLoggedUserName()
{
    return new Promise(resolve => {
        post(
            '/ajax/website/header/get-logged-user-name',
            {},
            function (response) {
                resolve(response.name);
            },
            function () {
                resolve(null);
            }
        );
    });
}

export function getLoggedUserData()
{
    return new Promise(resolve => {
        post(
            '/ajax/website/header/get-logged-user-data',
            {},
            function (response) {
                resolve(response.data);
            },
            function () {
                resolve(null);
            }
        );
    });
}

export function isPremiumUser()
{
    return localStorage.getItem('logged_user_premium') === 'true';
}
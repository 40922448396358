export function clickEvent(selector, dynamic, callback)
{
    if (dynamic) {
        document.addEventListener('click', function(event) {
            let element = event.target;
            if (event.detail && event.detail.dynamicTarget) {
                element = event.detail.dynamicTarget;
            }
            if (element.matches(selector)) {
                callback(element, event);
            } else if (element.closest(selector)) {
                callback(element.closest(selector), event);
            }
        });
    } else {
        let elements = document.querySelectorAll(selector);
        elements.forEach(function(element) {
            element.addEventListener('click', function(event) {
                callback(element, event);
            });
        });
    }
}

export function submitEvent(selector, dynamic, callback)
{
    captureEvent('submit', selector, dynamic, callback);
}

export function keyupEvent(selector, dynamic, callback)
{
    captureEvent('keyup', selector, dynamic, callback);
}

export function inputEvent(selector, dynamic, callback)
{
    captureEvent('input', selector, dynamic, callback);
}

export function changeEvent(selector, dynamic, callback)
{
    captureEvent('change', selector, dynamic, callback);
}

export function focusEvent(selector, dynamic, callback)
{
    captureEvent('focus', selector, dynamic, callback);
}

export function focusoutEvent(selector, dynamic, callback)
{
    captureEvent('focusout', selector, dynamic, callback);
}

export function resetEvent(selector, dynamic, callback)
{
    captureEvent('reset', selector, dynamic, callback);
}

export function mouseDownEvent(selector, dynamic, callback)
{
    captureEvent('mousedown', selector, dynamic, callback);
}

export function mouseUpEvent(selector, dynamic, callback)
{
    captureEvent('mouseup', selector, dynamic, callback);
}

export function mouseMoveEvent(selector, dynamic, callback)
{
    captureEvent('mousemove', selector, dynamic, callback);
}

export function mouseEnterEvent(selector, dynamic, callback)
{
    captureEvent('mouseenter', selector, dynamic, callback);
}

export function mouseLeaveEvent(selector, dynamic, callback)
{
    captureEvent('mouseleave', selector, dynamic, callback);
}

export function hoverEvent(selector, dynamic, enterCallback, leaveCallback)
{
    mouseEnterEvent(selector, dynamic, enterCallback);
    mouseLeaveEvent(selector, dynamic, leaveCallback);
}

export function mouseEnterElementEvent(element, callback)
{
    element.addEventListener('mouseenter', (event) => callback(element, event));
}

export function mouseLeaveElementEvent(element, callback)
{
    element.addEventListener('mouseleave', (event) => callback(element, event));
}

export function hoverElementEvent(element, enterCallback, leaveCallback)
{
    mouseEnterElementEvent(element, enterCallback);
    mouseLeaveElementEvent(element, leaveCallback);
}

export function errorEvent(selector, dynamic, callback)
{
    captureEvent('error', selector, dynamic, callback);
}

export function customEvent(event, selector, dynamic, callback)
{
    captureEvent(event, selector, dynamic, callback);
}

export function customDocumentEvent(event, callback)
{
    let events = event.split(' ');
    events.forEach(function(event) {
        document.addEventListener(event.trim(), function(event) {
            callback(event);
        });
    });
}

export function windowScrollEvent(callback)
{
    window.addEventListener('scroll', function(event) {
        callback(event);
    });
}

export function documentLoadEvent(callback)
{
    document.addEventListener( 'DOMContentLoaded', callback);
}

export function captureMultipleEvents(selector, events, dynamic, callback)
{
    events = events.replace(/\s/g, '').split(',');
    events.forEach(function(event) {
        captureEvent(event, selector, dynamic, callback);
    });
}

function captureEvent(event, selector, dynamic, callback)
{
    if (dynamic) {
        document.addEventListener(event, function (event) {
            let element = event.target;
            if (event.detail && event.detail.dynamicTarget) {
                element = event.detail.dynamicTarget;
            }
            if (typeof element.matches == 'function' && element.matches(selector)) {
                callback(element, event);
            }
        });
    } else {
        let elements = document.querySelectorAll(selector);
        elements.forEach(function(element) {
            element.addEventListener(event, function(event) {
                callback(element, event);
            });
        });
    }
}

export function fireClickEvent(object, dynamic)
{
    fireEvent('click', object, dynamic);
}

export function fireSubmitEvent(object, dynamic)
{
    fireEvent('submit', object, dynamic);
}

export function fireKeyupEvent(object, dynamic)
{
    fireEvent('keyup', object, dynamic);
}

export function fireResetEvent(object, dynamic)
{
    fireEvent('reset', object, dynamic);
}

export function fireScrollEvent(object, dynamic)
{
    fireEvent('scroll', object, dynamic);
}

export function fireChangeEvent(object, dynamic)
{
    fireEvent('change', object, dynamic);
}

export function fireFocusEvent(object, dynamic)
{
    fireEvent('focus', object, dynamic);
}

export function fireInputEvent(object, dynamic)
{
    fireEvent('input', object, dynamic);
}

function fireEvent(event, object, dynamic)
{
    if (dynamic) {
        document.dispatchEvent(new CustomEvent(event, {detail: {dynamicTarget: object}}));
    } else {
        object.dispatchEvent(new Event(event));
    }
}

export function dispatchCustomEvent(event, detail = {})
{
    let customEvent = new CustomEvent(event, {detail: detail});
    document.dispatchEvent(customEvent);
}
import {
    clickEvent,
    fireClickEvent,
    fireFocusEvent,
    fireKeyupEvent,
    fireSubmitEvent,
    focusEvent,
    keyupEvent,
    resetEvent,
    submitEvent
} from "../../webpack/modules/events";
import {hideElement, hideElementBySelector, showElement, slideDown, slideUp} from "../../webpack/modules/element-visibility";
import {makeUrl} from "../../webpack/modules/url";
import {isDesktop} from "../../webpack/modules/devices";
import {ga4TrackSimpleEvent, trackEvent} from "../../webpack/modules/analytics";
import {removeOverlay, renderOverlay} from "../../webpack/modules/overlay";
import {getLastSearches, removeSearch} from "../../webpack/modules/last-searches";
import {initSearcher} from "../../webpack/modules/algolia-searcher";
import {getCurrentLanguage} from "../../webpack/modules/locale";
import {getElasticSuggestions} from "../../webpack/modules/elasticsearch-searcher";

let maxSuggestions = isDesktop() ? 12 : 12;
let maxLastSearches = 4;

let availableAlgoliaSuggestions = (document.getElementById('autocomplete-method') && document.getElementById('autocomplete-method').value === 'ALG');

if (isDesktop()) resizeSuggestions();
window.addEventListener('resize', resizeSuggestions);
selectProductType();
loadLastSearch();

clickEvent('#header .left-menu-change-visibility', false, function() {
    hideSearcher();
});

clickEvent('.searcher .dropdown', false, function(dropdown) {
    let content = dropdown.querySelector('.dropdown-content');
    let icon = dropdown.querySelector('i');
    let action = !content.offsetWidth && !content.offsetHeight ? 'show' : 'hide';
    if (action === 'show') {
        slideDown(content);
        content.style.marginTop = '1px';
        icon.classList.remove('fa-caret-down');
        icon.classList.add('fa-caret-up');

    } else if (action === 'hide') {
        slideUp(content, function() {
            content.style.removeProperty('marginTop');
        });
        icon.classList.remove('fa-caret-up');
        icon.classList.add('fa-caret-down');
    }
});

clickEvent('.searcher .dropdown-option', false, function(element) {
    resetDropdownOptions();
    element.classList.add('selected');
    selectProductType();
});

submitEvent('.search-form', false, function(form, event) {
    event.preventDefault();
    let search = form.querySelector('input').value;
    let productType = document.querySelector('.search-type.selected');
    let searchType = form.querySelector('.search-type');
    let allProductsUrl = form.querySelector('.all-products-url');
    let url = productType ? productType.dataset.url : allProductsUrl.value;
    let params = {'q': search};
    let agsch = document.getElementById('agsch').dataset.status === 'true';
    let indexName = document.querySelector('#al-pi').value;

    let eventPayload = {
        event: 'searcher',
        search_term: search,
        method: searchType ? searchType.value : 'new-search'
    };
    let searchEventPayload = {
        event: 'search',
        search_term: search
    };
    if (agsch) {
        params['agsch'] = agsch;
        //trackEvent('Search', 'Searcher', 'Algolia');
        eventPayload['engine'] = 'algolia';
    } else {
        if (!indexName) {
            //trackEvent('Search', 'Searcher');
            eventPayload['engine'] = 'elastic';
        } else {
            //trackEvent('Search', 'Searcher', 'Algolia');
            eventPayload['engine'] = 'algolia';
        }
    }

    ga4TrackSimpleEvent(eventPayload);
    ga4TrackSimpleEvent(searchEventPayload);

    window.location.href = encodeURI(makeUrl('/'+url+'/search', params));
});

keyupEvent('.search-form input', false, function(element, e) {
    let disabledKeyboardCodes = [9, 13, 16, 17, 18, 19, 20, 27, 37, 38, 39, 40, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123]
    if (disabledKeyboardCodes.includes(e.keyCode)) return;
    let search = element.value;
    let searcher = element.closest('.search-form');
    let suggestions = searcher.querySelector('.suggestions');
    let lastSearches = searcher.querySelector('.last-searches');
    let tags = searcher.querySelector('.tags');
    showLastSearches(searcher);
    if (search !== '') showElement(searcher.querySelector('.btn-clear'));
    if (search === '') hideElement(searcher.querySelector('.btn-clear'));
    if (search.trim().length < 2) tags.innerHTML = '';
    if (search.trim().length >= 5 && !availableAlgoliaSuggestions) renderElasticSuggestions(searcher, maxSuggestions);
    if (!tags.innerHTML.length && !lastSearches.innerHTML.length) hideElement(suggestions);
});

clickEvent('.search-form .btn-clear', false, function(element) {
    let form = element.closest('form');
    let input = form.querySelector('input');
    input.focus();
    input.value = '';
    fireKeyupEvent(input, false);
});

focusEvent('.search-form input', false, function(element) {
    let searcher = element.closest('.search-form');
    let suggestions = searcher.querySelector('.suggestions');
    let lastSearches = searcher.querySelector('.last-searches');
    let tags = searcher.querySelector('.tags');
    if (document.querySelector('#header')) {
        let isShowed = document.querySelector('#header .overlay');
        if (!isShowed) openSearcher();
    }
    showLastSearches(searcher);
    if (tags.innerHTML.length) showElement(tags);
    if (tags.innerHTML.length || lastSearches.innerHTML.length) showElement(suggestions);
});

clickEvent('#header .overlay', true, function() {
    hideSearcher();
    hideElementBySelector('#header .search-form .last-searches');
    hideElementBySelector('#header .search-form .tags');
});

clickEvent('.left-menu', true, function(element, event) {
    let target = event.target;
    if (target.classList.contains('search-form') || target.closest(".search-form")) return;
    hideElementBySelector('.left-menu .search-form .last-searches');
    hideElementBySelector('.left-menu .search-form .tags');
});

clickEvent('body', false, function(element, e) {
    let target = e.target;
    let dropdown = document.querySelector('#searcher-product-type-selector');
    if (!dropdown) return;
    let isShowed = dropdown.querySelector('.dropdown-content').offsetHeight;
    if (isShowed && !target.closest('#searcher-product-type-selector') && target.id !== 'searcher-product-type-selector') {
        fireClickEvent(dropdown, false);
    }
});

clickEvent('body', false, function(element, e){
    let target = e.target;
    if (!target.closest('.header-searcher') && !target.classList.contains('header-searcher')) {
        hideElementBySelector('.header-searcher .search-form .suggestions');
    }
});

clickEvent('.search-form .remove-saved-search', true, function(element, e) {
    e.stopPropagation();
    e.preventDefault();
    let searcher = element.closest('.search-form');
    let input = searcher.querySelector('input');
    let suggestion = element.closest('.suggestion')
    let search = suggestion.dataset.suggestion;
    //trackEvent('Search', 'SearchHistory', 'Delete');
    ga4TrackSimpleEvent(
        {
            event: 'searcher',
            method: 'delete-saved-searches'
        }
    );
    removeSearch(search);
    showLastSearches(searcher);
    fireKeyupEvent(input, false);
});

window.addEventListener('native.hidekeyboard', function () {
    hideElementBySelector('.header-searcher .search-form .suggestions');
});

clickEvent('.search-form .suggestion', true, function(element, e) {
    if (e.target.classList.contains('remove-saved-search')) return;
    let form = element.closest('.search-form');
    let suggestion = element.dataset.suggestion;
    let input = form.querySelector('.search');
    input.value = suggestion;
    let searchType = form.querySelector('.search-type');
    searchType.value = element.classList.contains('saved-search') ? 'saved-search' : 'suggestion-search';
    /*if (element.classList.contains('saved-search')) {
        trackEvent('Search', 'SearchHistory', 'Open');
    } else {
        trackEvent('Search', 'Suggestion');
    }*/
    fireSubmitEvent(form);
});

resetEvent('.search-form', false, function(element, event) {
    event.preventDefault();
    //trackEvent('Search', 'SearchProduct', 'Delete');
    ga4TrackSimpleEvent(
        {
            event: 'searcher',
            method: 'delete'
        }
    );
});

function resetDropdownOptions()
{
    let options = document.querySelectorAll('#header .searcher .dropdown-option');
    for (let i = 0; i < options.length; i++) {
        options[i].classList.remove('selected');
    }
}

function selectProductType()
{
    let selectedOption = document.querySelector('#header .searcher .selected');
    let productType = null;
    if (selectedOption) {
        let name = selectedOption.dataset.name;
        let placeholder = selectedOption.dataset.placeholder;
        productType = selectedOption.dataset.id ? selectedOption.dataset.id.toString() : null;
        selectedOption.closest('.dropdown').querySelector('.name').innerHTML = name;
        document.querySelectorAll('#header .searcher input.search').forEach(function(element) {
            element.placeholder = placeholder;
        });

    }

    if (availableAlgoliaSuggestions) initSearchers(productType);
}

function openSearcher()
{
    let header = document.querySelector('#header');
    dataLayer.push({'event': 'optimize.algolia'});
    removeOverlay(header);
    renderOverlay(header, 'prepend');
    resizeSuggestions();
    //trackEvent('Search', 'SearchProduct', 'Type');
    ga4TrackSimpleEvent(
        {
            event: 'searcher',
            method: 'open'
        }
    );
}

function hideSearcher()
{
    let header = document.querySelector('#header');
    removeOverlay(header);
}

function showLastSearches(searcher)
{
    let lastSearches = getLastSearches();
    renderLastSearches(searcher, lastSearches);
}

function renderLastSearches(searcher, lastSearches)
{
    let suggestions = searcher.querySelector('.suggestions');
    let search = searcher.querySelector('.search').value;
    let div = searcher.querySelector('.suggestions .last-searches');
    let html = '';
    let suggestionsAdded = 0;
    div.innerHTML = '';
    window.lastSearches = [];
    if (search) {
        search = search.trim();
    }
    lastSearches.forEach(function (lastSearch) {
        if (suggestionsAdded >= maxLastSearches /*|| lastSearch === search*/) return;
        html += getSuggestionLineHTML(search.toLowerCase(), lastSearch.toLowerCase(), true);
        window.lastSearches.push(lastSearch);
        suggestionsAdded++;
    });
    div.insertAdjacentHTML('beforeend', html);
    if (div.innerHTML !== '') {
        showElement(div);
        showElement(suggestions);
    }
}

function getSuggestionLineHTML(search, suggestion, isSavedSearch = false)
{
    let normalText = '';
    let boldText = suggestion;

    if (!isSavedSearch) {
        normalText = search;
        boldText = suggestion.slice(search.length);
    }

    if (document.querySelector('header')) {
        return `
            <div class="col-12 py-1 suggestion ${isSavedSearch ? 'saved-search' : ''}" data-suggestion="${suggestion}">
                <button class="btn py-0 shadow-none remove-saved-search tvi tvi-times text-dark px-2 align-middle fw-bold ${isSavedSearch ? '' : 'invisible'}"></button>
                <span class="suggestion-name text-gray"><span>${normalText}</span>${boldText}</span>
            </div>
        `;
    } else {
        return `
            <div class="col-12 py-1 suggestion ${isSavedSearch ? 'saved-search' : ''}" data-suggestion="${suggestion}">
                <span class="remove-saved-search px-2 ${isSavedSearch ? '' : 'invisible'}">&times;</span>
                <span class="suggestion-name"><span>${normalText}</span>${boldText}</span>
            </div>
        `;
    }
}

function resizeSuggestions()
{
    if (document.querySelector('#header')) {
        let searcherForm = document.querySelector('#header #searcher-desktop');
        let searcher = searcherForm.querySelector('.searcher');
        let selector = searcherForm.querySelector('.dropdown');
        let suggestions = searcherForm.querySelector('.suggestions');
        let size = searcher.offsetWidth - selector.offsetWidth;
        suggestions.style.width = size+"px";
    }
}

function loadLastSearch()
{
    let params = new URLSearchParams(window.location.search);
    let lstkwParam = params.has('lstkw') ? params.get('lstkw') : null;
    let searchParam = params.has('q') ? params.get('q') : null;
    let search = lstkwParam || searchParam;
    let inputs = document.querySelectorAll('#header .search-form .search');
    if (search) {
        inputs.forEach(function(input) {
            input.value = search;
            showElement(input.closest('.input-group').querySelector('.btn-clear'));
        });
    }
}

function renderElasticSuggestions(searcher, maxSuggestions)
{
    getElasticSuggestions(searcher, maxSuggestions, function(suggestions) {
        let search = searcher.querySelector('.search').value;
        let addedSuggestions = getLastSearches();
        let countAddedSuggestions = addedSuggestions.length;
        let div = searcher.querySelector('.suggestions .tags');
        let divSuggestions = searcher.querySelector('.suggestions');
        let html = '';
        suggestions.forEach(function (suggestion) {
            if (countAddedSuggestions > maxSuggestions /*|| addedSuggestions.includes(suggestion)*/) return;
            html += getSuggestionLineHTML(search, suggestion);
            countAddedSuggestions++;
        });
        div.innerHTML = html;

        if (html !== '') {
            showElement(div);
            showElement(divSuggestions);
        } else {
            hideElement(div);
        }
    });
}

function initSearchers(productType)
{
    let searchers = document.querySelectorAll('.search-form');

    searchers.forEach(function(searcher) {
        initSearcher(searcher, getCurrentLanguage(), productType, maxSuggestions);
    });
}
import {post} from "./ajax";
import {
    hideElement,
    showElement,
    slideDown,
    slideUp
} from "./element-visibility";
import {renderAmazonPayButton} from "./amazon-pay";
import {renderPayPalButton} from "./paypal";
import {getDeviceType} from "./devices";
import {initEmptyCartRecommender, initLastViewedProductsRecommender} from "./recommender";
import {openModalFromHtml} from "./modal";
import {previewScopeOptions} from "./old/product";
import Splide from "@splidejs/splide";
import {getCookie} from "../../../library/cookies";
import {getLastViewedProducts} from "./last-viewed-products";
import {document} from "../../../../../public/vendor/ssr-window";
import {adjustArrows} from "../modules-optimized/recommenders";
import CssFilterConverter from "css-filter-converter";

let deviceType = getDeviceType();

export function openCart()
{
    let cart = document.querySelector('#cart');
    let offcanvas = bootstrap.Offcanvas.getOrCreateInstance(cart);
    offcanvas.show();
    renderCart();
    // moveStickyCart();
}

export function hideCart()
{
    let cart = document.querySelector('#cart');
    let offcanvas = bootstrap.Offcanvas.getOrCreateInstance(cart);
    offcanvas.hide();
    cart.classList.remove('cart-showed');
}

export function renderLoader()
{
    let cart = document.querySelector('#cart');
    let hasLoader = cart.querySelector('.cart-loader');
    if (hasLoader) return;
    let offCanvasBody = cart.querySelector('.offcanvas-body');
    let loader = getLoaderHtml();
    if (offCanvasBody) offCanvasBody.insertAdjacentHTML('afterend', loader);
    else cart.innerHTML += loader;
}

function getLoaderHtml()
{
    return `
    <div class="cart-loader d-flex bg-white bg-opacity-75 position-absolute top-0 w-100 h-100 align-items-center justify-content-center z-index-inherit">
        <div class="spinner-border"></div>
    </div>
    `;
}

export function showDropdown(dropdown, animate = false)
{
    let icon = dropdown.querySelector('.header i');
    let body = dropdown.querySelector('.body');
    icon.classList.remove('fa-chevron-down');
    icon.classList.add('fa-chevron-up');
    if (animate) {
        slideDown(body);
    } else {
        showElement(body);
    }
}

export function hideDropdown(dropdown, animate = false)
{
    let icon = dropdown.querySelector('.header i');
    let body = dropdown.querySelector('.body');
    icon.classList.remove('fa-chevron-up');
    icon.classList.add('fa-chevron-down');
    if (animate) {
        slideUp(body);
    } else {
        hideElement(body);
    }
}

let ajaxRender = null;
export function renderCart(callback)
{
    if (ajaxRender) ajaxRender.abort();
    let cart = document.querySelector('#cart');
    let cartType = cart.dataset.type;
    let renderNewCart = document.querySelector('#render-new-cart').dataset.status;
    if (renderNewCart === 'true' && !cart.classList.contains('new-cart')) cart.classList.add('new-cart');
    renderLoader();
    ajaxRender = post(
        '/ajax/website/cart/render',
        {
            renderNew: renderNewCart,
            cartType: cartType,
        },
        function (response) {
            let data = response.data;
            cart.innerHTML = data.view;
            let cartIsVisible = document.querySelector('#cart').classList.contains('cart-showed');
            if (data.dataLayer && !cartIsVisible) {
                eval(data.dataLayer);
                cart.classList.add('cart-showed');
            }
            ajaxRender = null;
            if (cart.querySelector('#amazon-pay-button')) renderAmazonPayButton(data.amazonPayCredentials);
            if (cart.querySelector('#paypal-button')) renderPayPalButton(data.currency, data.total);
            initComplementsSlider();
            initImagesPreview();
            if (callback) callback();
        },
        function(response) {
            let data = response.data;
            cart.innerHTML = data.view;
            ajaxRender = null;
            initEmptyCartRecommender();
            renderLastViewedProductRecommender();
            if (callback) callback();
        }
    );
}

function renderLastViewedProductRecommender()
{
    post(
        '/ajax/website/product-list/get-last-viewed-products-recommender',
        {lastViewedProducts: getLastViewedProducts()},
        function(data) {
            let recommender = data.recommender;
            let container = document.querySelector('#cart-last-viewed-products-container');
            container.innerHTML = recommender;
            let recommenders = document.querySelectorAll('#cart #cart-last-viewed-products-container .recommender[data-type="last-viewed-products"]');
            recommenders.forEach(function(recommender) {
                let splide = new Splide(recommender, {
                    type: 'loop',
                    perPage: 2,
                    perMove: 1,
                    lazyLoad: 'nearby',
                    gap: 8,
                    pagination: false,
                    rewind: true,
                    arrows: false,
                    padding: {right: '23%'}
                }).mount();
                splide.on('mounted resize', function () {
                    adjustArrows(recommender);
                });
            });
        }
    );
}

let ajaxAddItem = null;
export function addItem(data, callbackSuccess, callbackError)
{
    if (ajaxAddItem) ajaxAddItem.abort();
    ajaxAddItem = post(
        '/ajax/website/cart/add-item',
        data,
        function(response) {
            updateItemsNumber(true);
            ajaxAddItem = null;
            if (callbackSuccess) callbackSuccess(response.items);
        },
        function(response) {
            ajaxAddItem = null;
            if (callbackError) callbackError(response.productIndex, response.type, response.message, response.productMessage);
        }
    );
}

let ajaxAddItems = null;
export function addItems(data, callbackSuccess, callbackError)
{
    if (ajaxAddItems) ajaxAddItems.abort();
    ajaxAddItems = post(
        '/ajax/website/cart/add-items',
        data,
        function(response) {
            updateItemsNumber(true);
            ajaxAddItems = null;
            if (callbackSuccess) callbackSuccess(response.items);
        },
        function(response) {
            ajaxAddItems = null;
            if (callbackError) callbackError(response.productIndex, response.type, response.message);
        }
    );
}

let ajaxModifyItem = null;
export function modifyItem(data, callbackSuccess, callbackError)
{
    if (ajaxModifyItem) ajaxModifyItem.abort();
    ajaxModifyItem = post(
        '/ajax/website/cart/modify-item',
        data,
        function(response) {
            ajaxModifyItem = null;
            if (callbackSuccess) callbackSuccess(response.items);
        },
        function(response) {
            ajaxModifyItem = null;
            if (callbackError) callbackError(response.productIndex, response.type, response.message);
        }
    );
}


let ajaxItemQuantity = null;
export function updateItemQuantity(itemId, quantity, callback)
{
    if (ajaxItemQuantity) ajaxItemQuantity.abort();
    ajaxItemQuantity = post(
        '/ajax/website/cart/update-item-quantity',
        {
            id: itemId,
            quantity: quantity
        },
        function(data) {
            ajaxItemQuantity = null;
            if (callback) callback(data.oldQuantity);
        },
        function () {
            ajaxItemQuantity = null;
            if (callback) callback();
        }
    )
}

export function removeItem(itemId, callback)
{
    post(
        '/ajax/website/cart/remove-item',
        {id: itemId},
        function () {
            if (callback) callback();
        },
        function() {
            if (callback) callback();
        }
    );
}

export function loadModifyItem(itemId)
{
    post(
        '/ajax/website/cart/load-modify-item',
        {id: itemId},
        function (data) {
            openModalFromHtml(data.view);
            previewScopeOptions(document.querySelector('#product-popup'));
        }
    )
}

export function updateCustomText(modal)
{
    let textarea = modal.querySelector('.customText');
    if (textarea) {
        let maxRows = textarea.dataset.maxRows;
        let rows = textarea.rows;
        let lines = parseInt(textarea.scrollHeight/parseInt(getComputedStyle(textarea).lineHeight) )

        textarea.rows = lines < maxRows ? lines : 8;
    }
}

export async function updateItemsNumber(update = false)
{
    let items = await getItemsNumber(update);
    setCartItem(items);
}

export function showShareCartLink()
{
    post(
        '/website/cart/get-share-link',
        {},
        function(data) {
            openModalFromHtml(data.view);
        }
    )
}

export function initComplementsSlider()
{
    let complements = document.querySelector('#cart #cart-complements');

    if (complements) {
        new Splide(complements, {
            perPage: 2,
            perMove: 1,
            lazyLoad: 'sequential',
            arrows: false,
            pagination: false,
            padding: { right: '20%'},
            gap: 8,
        }).mount();
    }
}

export function setCartItem(items)
{
    let elements = document.querySelectorAll('#header .open-cart .items');
    elements.forEach(function(element) {
        if (items === 0) item.innerHTML = '';
        else element.innerHTML = items;
    });

    let newItems = document.querySelectorAll('header .open-cart .items');
    newItems.forEach(function(item) {
        if (items === 0) item.innerHTML = '';
        else item.innerHTML = items;
    });
}

function getItemsNumber(update) {
    return new Promise((resolve) => {
        let cookie = getCookie('_tv_shopping_cart');
        if (cookie === '') resolve(0);
        if (!update && localStorage.getItem("cartNumberItems")) {
            resolve(parseInt(localStorage.getItem("cartNumberItems")));
        } else {
            post(
                '/ajax/website/cart/get-items-number',
                {},
                function(data) {
                    localStorage.setItem('cartNumberItems', data.items);
                    resolve(parseInt(data.items));
                },
                function () {
                    resolve(0);
                }
            )
        }
    });
}

function initImagesPreview()
{
    let itemsImages = document.querySelectorAll('#cart .cart-item img');

    if (!itemsImages) return;

    itemsImages.forEach(function(image) {
        let hexCode = image.dataset.colorHexCode;
        let mirrored = image.dataset.mirrored;

        if (hexCode && hexCode.includes('ffffff')) image.parentElement.style.backgroundColor = '#CDCDCD';
        else image.parentElement.style.backgroundColor = '';
        if (hexCode) image.style.filter = CssFilterConverter.hexToFilter(hexCode)['color'];
        else image.style.filter = '';
        if (mirrored === 'true') image.style.transform = 'scaleX(-1)';
        else image.style.transform = '';
    });
}
